import { Forbidden } from "@/components/Status/Forbidden";
import { InternalError } from "@/components/Status/InternalError";
import { Loading } from "@/components/Status/Loading";
import { MainTitle } from "@/components/Typography";
import { useAuth } from "@/Providers/AuthProvider";
import { isStaffingManager } from "@/Providers/permissions";

export function StaffingSuggestionsIndex() {
  const { api, roles } = useAuth();

  if (isStaffingManager(roles)) {
    return <Forbidden />;
  }

  const { isPending, isError, data } = api.fetchOpenStaffingSuggestions();

  if (isPending) {
    return <Loading />;
  }

  if (isError) {
    return <InternalError />;
  }

  return (
    <>
      <div>
        <MainTitle text="Staffing Suggestions" />
      </div>
      <p>
        This is an admin only page - its just debug info atm. Please ignore --
        Dylan
      </p>

      <>
        {data.map((s) => {
          return (
            <div key={s.id}>
              {s.created_at} - {s.project_user_id}
              <pre>{JSON.stringify(s.suggestions, null, 2)}</pre>
            </div>
          );
        })}
      </>
    </>
  );
}
